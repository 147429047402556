import { CampusCardTag, CampusModuleCard } from 'atlas-ds';
import { Module, OrganismeFormationExt } from 'services/model';
import { linkConstructor } from './link';


export default function ModuleCard(props: { module: Module, entreprise?: OrganismeFormationExt }) {
    const link = props.entreprise && props.module.stages && props.module.stages.length > 0
        ? '/stages/' + props.module.path.replace('module', 'stage') + '/' + props.module.stages[0]
        : `/modules/${props.module.path}/${props.module.id}` + (props.entreprise?.id ? `?entreprise=${props.entreprise.id}` : '') 

    return <CampusModuleCard
        href={link}
        linkConstructor={linkConstructor}
        label={ props.module.titre }
        infos={[
            ...!props.entreprise && props.module.organismes ? [{
                value: props.module.organismes,
                label: `organisme${ (props.module.organismes ?? 0) > 1 ? 's' : ''} de formation`
            }] : [],
            ...props.module.sessions ? [{
                value: props.module.sessions,
                label: `session${(props.module.sessions ?? 0) > 1 ? 's' : ''}`
            }] : []
        ]}
        tag={ props.module.fne ? <CampusCardTag
            label="FNE"
            tooltipContent={<>
                <p>Cette action de formation bénéficie du soutien de l’Etat au titre du FNE-Formation car elle concourt à la prise en compte et l’accélération des transitions numérique et/ou écologique.</p>
                <p><a href="https://www.opco-atlas.fr/article/comment-beneficier-du-fne-formation-pour-accompagner-les-transitions-numerique-et-ecologique-dans.html">En savoir plus</a></p>
            </>}
        /> : undefined}
    />
}
